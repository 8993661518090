import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Normalize } from "styled-normalize";
import { format } from "date-fns";

import styled, {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from "styled-components";

export type Props = {
  title: string;
};

const colors: DefaultTheme = {
  main: "#3E6668",
  lightShades: "#d1cdb9",
  lightAccent: "#71aaa8",
  darkAccent: "#568f99",
  darkShades: "#171c1a",
  darkText: "#171c1a",
  lightText: "#ffffff",
};

const GlobalStyle = createGlobalStyle`
  body{ 
    margin: 0;
    font-family: Quicksand, Roboto, Helvetica;
  }

  h1, h2, h3, h4, h4, h5 {
    font-family: hepta slab;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const Main: React.FC<Props> = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
        buildTime
      }
    }
  `);

  return (
    <ThemeProvider theme={colors}>
      <Container>
        <GlobalStyle />
        <Normalize />
        <Nav>
          <Link to="/">
            <H1>{data.site.siteMetadata.title}</H1>
            {/* <H2>{title}</H2> */}
          </Link>
          <Menu>
            <li>
              <Link to="/recipes">All Recipes</Link>
            </li>
          </Menu>
        </Nav>
        <main>{children}</main>
        <Footer>
          &copy; {format(new Date(data.site.buildTime), "yyyy")} Iolo Pearse.
          All Rights Reserved.
        </Footer>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  padding-bottom: 120px;
`;

const Nav = styled.nav`
  background: ${(p) => p.theme.main};
  color: ${(p) => p.theme.lightText};
  padding: 4px;
`;

const H1 = styled.h1`
  margin: 0;
`;

const H2 = styled.h2`
  margin: 0;
`;

const Menu = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;

  li {
    display: block;
    list-style: none;
    padding: 4px;
  }
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: ${(p) => p.theme.main};
  color: ${(p) => p.theme.lightText};
  padding: 16px;
`;

export default Main;
